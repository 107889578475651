// Styles

// Main styles

@import "./config/default/bootstrap.scss";
@import "./config/default/app.scss";

// Minton Icons
@import "./icons.scss";


.scroll_shift {scroll-margin-top: 80px;}

.fullpage {
    //display: block;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    //height: 100vh;
    //background-size: contain;
    //background-repeat: no-repeat no-repeat;
    //background-position: center center;
    // background-color: black;
  }

  span.email b {
    display: none;
  }

   /* Match typical <p> styling */
   .h1-like-p {
    font-size: 1em;         /* Matches default paragraph size (16px usually) */
    font-weight: normal;    /* Removes bold */
    margin: 1em 0;          /* Matches typical <p> margins */
    line-height: 1.5;       /* Common paragraph readability */
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.table-header-rotated {
  th.rotate {
    height: 140px;
    white-space: nowrap;
    > div {
      transform:
        translate(-9px, 0px)
        rotate(-45deg);
      width: 30px;
    }
    > div > span {
      padding: 5px 10px;
    }
  }
}