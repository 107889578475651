// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
  font-size: 100%;
}

body {
  overflow-x: hidden;
  font-size: 1.0em; /* That is your text's default font size. Here i chose 12px */
}
